import React from 'react';
import _ from 'lodash';
import { Row, Col, Form, Alert, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { Form, Alert, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Formik, validateYupSchema, yupToFormErrors, FormikValues, FormikTouched } from 'formik';
import gql from 'graphql-tag';
import { withApollo, WithApolloClient } from 'react-apollo';
import { withRouter, RouteComponentProps, Prompt } from 'react-router-dom';
import ApolloClient, { MutationOptions, ApolloError } from 'apollo-client';
import { History } from 'history';
import withGraphQuery from '../GraphQuery/GraphQuery';
import { createAccountMutation } from './mutations';
import { createAccountFormSchema } from './schemas';
import { LoggerFactory } from '../../util/LoggerFactory';
import * as links from '../SharedComponents/Links';
import { redAsterisk } from '../SharedComponents/RedAsterisk';
import { UserTypeahead } from './UserTypeahead';
import QueryError from '../../components/GraphQuery/QueryError';
import './CreateAccount.scss';
import { PartitionSelect } from './PartitionSelect';
// import { CicdInput } from './CicdInput';
import { TaggingStandard, TagKeyPair } from '../TaggingStandard/types';
// import { TaggingStandard } from '../TaggingStandard/types';
import { Editor as TagEditor } from '../TaggingStandard/Editor';
import { validateTags, validateTagsFromServer, asTagList, tagsFromStandard } from '../TaggingStandard/util';
// import { validateTagsFromServer, asTagList, tagsFromStandard } from '../TaggingStandard/util';
import { LoadingWidget } from '../LoadingState/LoadingWidget';
import * as services from '../ServiceAvailability/services';

const log = LoggerFactory.getLogger('Create Account');

export function removeElement<T>(element: T, array: T[]): T[] {
  const result: T[] = [];
  array.forEach((ele: T) => {
    if (ele !== element) {
      result.push(ele);
    }
  });
  return result;
}

export interface FormTextFieldProps {
  label: string;
  name: string;
  required: boolean | null;
  formik: FormikValues;
}

export class FormTextField extends React.Component<FormTextFieldProps> {
  render(): React.ReactNode {
    const { label, name, formik, required } = this.props;
    const { values, errors, touched, handleChange, handleBlur } = formik;
    const isInvalid = _.get(touched, name) && _.get(errors, name);
    return (
      <Form.Group>
        <Form.Label>
          {label} {required && redAsterisk}
        </Form.Label>
        <Form.Control
          type="text"
          name={name}
          value={_.get(values, name)}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={isInvalid}
        />
        <Form.Control.Feedback type="invalid">{_.get(errors, name)}</Form.Control.Feedback>
      </Form.Group>
    );
  }
}

export const baseInitialFormValues = {
  account: {
    name: '',
    accountType: '',
    features: {
      route53: {
        nikecloud: false,
      },
      serviceCatalog: {
        wafflebar: false,
      },
      cicd: {
        terraform: false,
        brewmasters: [],
      },
      nikePlatformEngine: {
        enabled: false,
      },
    },
  },
  contact: {
    business: '',
    billing: '',
    security: '',
    technical: '',
  },
  partition: 'GLOBAL',
  isTosAccepted: false,
  isProd: false,
  isTest: false,
  isSandbox: false,
  isAccountTypeSelected: false,
  isPartitionSelected: false,
  isChina: false,
  tags: null,
  globalTagErrors: null,
};

export function deriveAccountTypes(formData: FormikValues): string[] {
  // const { isTest, isProd, isSandbox, isChina } = formData;
  const { isTest, isProd, isSandbox } = formData;

  const accountTypes = [];
  if (isSandbox) {
    accountTypes.push('SANDBOX');
  } else {
    if (isTest) {
      accountTypes.push('TEST');
    }
    if (isProd) {
      accountTypes.push('PROD');
    }
  }

  return accountTypes;
}
export function loadValidAccountTypes(): string[] {
  const allAccountTypes = [];
  allAccountTypes.push('SANDBOX');
  allAccountTypes.push('TEST');
  allAccountTypes.push('PROD');
  allAccountTypes.push('DEV');
  allAccountTypes.push('NONPROD');
  allAccountTypes.push('SBX');

  return allAccountTypes;
}

interface ExistingAccount {
  name: string | null;
  accountType: string | null;
}

export function existingAccountError(
  existingAccounts: ExistingAccount[],
  accountName: string,
  accountTypes: string[]
): string | null {
  const conflictingAccounts = (existingAccounts || []).filter((account: ExistingAccount) => {
    return account.name === accountName && accountTypes.includes(account.accountType as string);
  });
  const conflictingAccountTypes = conflictingAccounts.map((account: ExistingAccount) => account.accountType);
  return !conflictingAccounts.length
    ? null
    : `Account "${accountName}" already exists for account types [${conflictingAccountTypes.join(', ')}]`;
}
// WI API validation: no whitespace in name; only letters, digits, '-' and ' ' as symbols allowed; length <= 24; only lowercase names; no prefix or suffix acct type in names;
// no acct types as names (special corner case)
export function validateAccountName(accountName: string): string | null {
  let warning = '';
  if (accountName.length > 24) {
    warning += `"${accountName}" exceeded the maximum 24 characters. `;
  }
  if (accountName.match(/[^A-Za-z0-9-]/)) {
    warning += `"${accountName}" contains invalid symbols. `;
  }
  if (accountName.includes(' ')) {
    warning += `"${accountName}" cannot contain whitespace. `;
  }
  if (accountName.toLowerCase() !== accountName) {
    warning += `"${accountName}" must be all lower case. `;
  }
  const typeInName = typeAsAccountNameError(accountName);
  if (typeInName != null) {
    warning += typeInName;
  }
  return !warning.length ? null : warning;
}
export function typeAsAccountNameError(accountName: string): string | null {
  let found = false;
  if (accountName !== '') {
    loadValidAccountTypes().forEach((el) => {
      if (
        el.toLowerCase().includes(accountName.toLowerCase()) ||
        accountName.startsWith(el.toLowerCase() + '-') ||
        accountName.endsWith('-' + el.toLowerCase())
      ) {
        found = true;
      }
    });
  }
  return !found ? null : `Account name "${accountName}" cannot be an account name, as it contains a Waffle Iron type.`;
}

export function noAccountTypeError(formData: FormikValues): string | null {
  if (!(formData.isSandbox || formData.isProd || formData.isTest)) {
    return 'Please select at least one account type';
  } else {
    return null;
  }
}

export function noPartitionError(formData: FormikValues): string | null {
  if (!formData.isPartitionSelected) {
    return 'Please select a Partition';
  } else {
    return null;
  }
}

export function tfRequiresBmxError(formData: FormikValues): string | null {
  let errorMsg = 'You must provide at least one BMX arn to enable Terraform integration';
  if (formData.isChina) {
    errorMsg = 'You must enable CI/CD integration to enable Terraform integration';
  }
  if (
    _.get(formData, 'account.features.cicd.terraform') &&
    _.get(formData, 'account.features.cicd.brewmasters').length === 0
  ) {
    return errorMsg;
  }
  return null;
}

function validateForm(existingAccounts: ExistingAccount[], formData: FormikValues): any {
  const accountTypes = deriveAccountTypes(formData);
  const accountNameError = existingAccountError(existingAccounts, formData.account.name, accountTypes);
  const invalidAccountNameError = validateAccountName(formData.account.name);
  const missingAccountType = noAccountTypeError(formData);
  const missingPartition = noPartitionError(formData);
  // const tfError = tfRequiresBmxError(formData);
  const tagErrors = validateTags(formData.taggingStandard, formData.tags);
  const baseErrors: any = {};
  if (accountNameError) {
    _.set(baseErrors, 'account.name', accountNameError);
  }

  if (invalidAccountNameError) {
    _.set(baseErrors, 'account.name', invalidAccountNameError);
  }

  if (missingAccountType) {
    _.set(baseErrors, 'noAccountType', missingAccountType);
  }

  if (missingPartition) {
    _.set(baseErrors, 'noPartition', missingPartition);
  }

  // if (tfError) {
  //   _.set(baseErrors, 'account.features.cicd.terraform', tfError);
  // }

  if (!_.isEmpty(tagErrors)) {
    _.set(baseErrors, 'tags', tagErrors);
  }

  return new Promise((resolve, reject) => {
    return validateYupSchema(formData, createAccountFormSchema)
      .then(() => resolve(baseErrors))
      .catch((err: any) => {
        if (err.name === 'ValidationError') {
          const yupErrors = yupToFormErrors(err);
          log.debug('yupErrors', yupErrors);
          if (accountNameError) {
            _.set(yupErrors, 'account.name', accountNameError);
          }
          if (invalidAccountNameError) {
            _.set(yupErrors, 'account.name', invalidAccountNameError);
          }
          if (missingAccountType) {
            _.set(yupErrors, 'noAccountType', missingAccountType);
          }
          if (missingPartition) {
            _.set(yupErrors, 'noPartition', missingPartition);
          }
          // if (tfError) {
          //   _.set(yupErrors, 'account.features.cicd.terraform', tfError);
          // }
          if (!_.isEmpty(tagErrors)) {
            _.set(yupErrors, 'tags', tagErrors);
          }
          resolve(yupErrors);
        } else {
          reject(err);
        }
      });
  });
}

interface CreateAccountFormProps extends RouteComponentProps<any> {
  apollo: ApolloClient<any>;
  existingAccounts: ExistingAccount[];
  taggingStandard: TaggingStandard;
  hasChinaCreatePermission: boolean;
  history: History;
}

interface CreateAccountFormState {
  mutationResponses: any[];
  isLoading: boolean;
  loadingState: string | undefined | null;
  preventNav: boolean;
  isSubmitting: boolean;
}

export class CreateAccountForm extends React.PureComponent<CreateAccountFormProps, CreateAccountFormState> {
  private tagsRef: React.RefObject<HTMLInputElement>;
  constructor(props: CreateAccountFormProps) {
    super(props);
    this.state = {
      mutationResponses: [],
      isLoading: false,
      loadingState: undefined,
      preventNav: false,
      isSubmitting: false,
    };
    this.tagsRef = React.createRef();
  }
  validateFormikf = () => {
    this.setState({ preventNav: true });
  };

  createAccountRequest = (formData: FormikValues, accountType: string) => {
    const { apollo } = this.props;
    const { account, contact, partition, tags } = formData;
    const formattedTags = asTagList(tags);

    const mutateParams: MutationOptions = {
      mutation: createAccountMutation,
      errorPolicy: 'all',
      variables: {
        account: {
          ..._.omit(account, ['partition']),
          accountType: accountType,
        },
        tags: formattedTags,
        contact: contact,
        partition: partition,
      },
    };
    return apollo.mutate(mutateParams);
  };

  onSubmit = (formData: any, actions: any) => {
    if (this.state.isSubmitting) {
      return;
    }
    this.setState({ isLoading: true, loadingState: 'validating tag values...', preventNav: false, isSubmitting: true });
    return validateTagsFromServer({ apollo: this.props.apollo, tags: formData.tags }).then((validationResponse) => {
      if (validationResponse.status !== 'pass') {
        actions.setFieldValue('tagValidationResponse', validationResponse);
        this.setState({ isLoading: false, loadingState: null, isSubmitting: false });
        this.tagsRef.current?.scrollIntoView();
      } else {
        const accountTypes = deriveAccountTypes(formData);
        this.setState({ loadingState: 'submitting creation request...' });
        return Promise.all(accountTypes.map(this.createAccountRequest.bind(this, formData)))
          .then((results) => {
            this.setState({ isLoading: false, loadingState: null, isSubmitting: false });
            const jobIds = results.map(({ data }) => _.get(data, 'createAccountRequest.jobId'));
            if (_.some(jobIds, _.isEmpty)) {
              this.setState({ mutationResponses: results });
              window.scroll(0, 0);
            } else {
              const jobIdsCsv = encodeURIComponent(jobIds.join(','));
              this.props.history.push(`/accounts/create-status/${jobIdsCsv}`);
            }
          })
          .catch((err: ApolloError) => {
            log.debug('error creating account:', err);
          });
      }
    });
  };

  render(): React.ReactNode {
    const { hasChinaCreatePermission, taggingStandard } = this.props;
    const { mutationResponses, isLoading, loadingState } = this.state;
    return (
      <div>
        {mutationResponses.map(({ data, errors }, i) => (
          <QueryError key={i} errors={{ graphQLErrors: errors }} />
        ))}
        <Alert variant="danger">STOP - READ THE FOLLOWING IMPORTANT INFORMATION BEFORE CONTINUING</Alert>
        <div className="create-account-form">
          <Formik
            validate={validateForm.bind(null, this.props.existingAccounts)}
            onSubmit={this.onSubmit}
            initialValues={Object.assign({}, baseInitialFormValues, {
              taggingStandard,
              tags: tagsFromStandard(taggingStandard),
            })}
          >
            {(formikStuff: FormikValues) => {
              const { handleSubmit, values, errors, setFieldValue, touched, setFieldTouched, isValid } = formikStuff;
              return (
                <Form onSubmit={handleSubmit} onChange={this.validateFormikf}>
                  <Prompt
                    when={this.state.preventNav}
                    message={(location) => 'Are you sure you want to leave the page without saving?'}
                  />
                  <p>
                    Taking ownership of an AliCloud account implies that you will be responsible for deploying solutions that
                    are well architected and adhere to best practices, which will be a mix of vendor (e.g., AliCloud)
                    recommendations, industry best practices, and Nike's evolving standards. You will also be 100%
                    responsible for the day to day management of the account. You will need to staff and/or train for
                    this. No centralized AliCloud Account Administration is provided. Informal assistance may be available
                    via community resources (Slack, Confluence, etc), but is not guaranteed. The responsibilities of
                    account management include, but are not limited to:
                  </p>
                  <ul>
                    <li>Managing access by users to your account</li>
                    <li>Managing the financial efficiency of your account</li>
                    <li>Responding to CIS requests for violation remediation</li>
                    <li>Deployment/Management of infrastructural scaffolding</li>
                    <li>Service limits / Limit increases</li>
                    <li>User Access/Management</li>
                    <li>Resource Management</li>
                    <li>Pipeline(s) integration</li>
                    <li>Key rotation</li>
                    <li>Security</li>
                    <li>etc...</li>
                  </ul>
                  <p></p>
                  <p>
                    Waffle Iron accounts are transparent to CIS and will be held to a security benchmark through
                    automation. At a minimum, Waffle Iron accounts will be compliant with the rules and standards
                    defined in the {links.foundationsBenchmarkLink}, and set by the {links.infoSecurityProgramLink}.
                  </p>
                  <p>
                    Governance rule violations will be deleted on discovery. You are expected to be aware of the rules
                    and/or accept that automated governance may destroy any solutions that violate them.
                  </p>
                  <p>
                    For more information on governance rules and responses, please visit{' '}
                    {links.enterpriseCloudGovernanceLink}.
                  </p>
                  <p>Please review the {links.waffleIronFaqLink} for more information.</p>
                  <Form.Group>
                    <div className="font-weight-bold">
                      <Form.Check
                        type="checkbox"
                        name="isTosAccepted"
                        label="I understand the requirements and accept the responsibilities for the accounts I am requesting"
                        checked={values.isTosAccepted}
                        onChange={() => setFieldValue('isTosAccepted', !values.isTosAccepted, false)}
                      />
                    </div>
                  </Form.Group>

                  {values.isTosAccepted && (
                    <div>
                      <Card className="mb-2">
                        <Card.Body>
                          <Card.Title>Account Information</Card.Title>
                          <FormTextField name="account.name" label="Account Name" required formik={formikStuff} />
                          <PartitionSelect hasChinaCreatePermission={hasChinaCreatePermission} formik={formikStuff} />
                          <Form.Group>
                            <Form.Control
                              as="select"
                              isInvalid={touched.isAccountTypeSelected && !values.isAccountTypeSelected}
                              onBlur={() => setFieldTouched('isAccountTypeSelected', true)}
                              onChange={(evt: any) => {
                                const val = evt.target.value;
                                setFieldTouched('isAccountTypeSelected', true);
                                if (val === 'sandbox') {
                                  setFieldValue('isSandbox', true, false);
                                  setFieldValue('isAccountTypeSelected', true, false);
                                } else if (val === 'non-sandbox') {
                                  setFieldValue('isSandbox', false, false);
                                  setFieldValue('isAccountTypeSelected', true, false);
                                } else {
                                  setFieldValue('isAccountTypeSelected', false, false);
                                }
                              }}
                            >
                              <option value="default">Please select an account type</option>
                              <option value="non-sandbox">Test / Prod</option>
                              {/* <option value="sandbox">
                                Sandbox
                              </option> */}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              You must choose a valid account type
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Alert variant="secondary">
                            Note: Sandbox accounts will be{' '}
                            <strong>automatically deleted 14 days after account creation</strong>. Production workloads
                            are NOT PERMITTED in sandbox accounts, for production workloads please use the Prod account
                            type.
                          </Alert>
                          {values.isAccountTypeSelected && !values.isSandbox && (
                            <Form.Group>
                              <Form.Label>
                                Account Type (Selection of multiple will result in multiple AWS accounts being created){' '}
                                {redAsterisk}
                              </Form.Label>
                              <Form.Check
                                type="checkbox"
                                name="isProd"
                                label="PROD"
                                checked={values.isProd}
                                onChange={() => setFieldValue('isProd', !values.isProd)}
                                isInvalid={errors.noAccountType}
                                feedback={errors.noAccountType}
                              />
                              <Form.Check
                                type="checkbox"
                                name="isTest"
                                label="TEST / NON-PROD"
                                checked={values.isTest}
                                onChange={() => setFieldValue('isTest', !values.isTest)}
                                isInvalid={errors.noAccountType}
                                feedback={errors.noAccountType}
                              />
                            </Form.Group>
                          )}
                        </Card.Body>
                      </Card>
                      {/* <Card className="mb-2">
                        <Card.Body>
                          <Card.Title>Waffle Iron Features</Card.Title>
                          <Form.Group>
                            <Form.Check
                              type="checkbox"
                              name="account.features.route53.nikecloud"
                              label="Route 53"
                              checked={
                                values.account.features.route53.nikecloud && !values.isSandbox && !values.isChina
                              }
                              disabled={values.isSandbox || values.isChina}
                              onChange={() =>
                                setFieldValue(
                                  'account.features.route53.nikecloud',
                                  !values.account.features.route53.nikecloud
                                )
                              }
                            />
                            {values.isSandbox && (
                              <div>Route 53 integration is currently unsupported in sandbox accounts</div>
                            )}
                            {!values.isSandbox && (
                              <p>
                                Creates a Route53 record with the following CNAME:
                                '&lt;ACCOUNT_NAME&gt;-&lt;ENV&gt;.nikecloud.com '.
                              </p>
                            )}
                          </Form.Group>
                          <Form.Group>
                            <Form.Check
                              type="checkbox"
                              name="account.features.cicd.terraform"
                              label="Terraform"
                              checked={values.account.features.cicd.terraform}
                              isInvalid={Boolean(_.get(errors, 'account.features.cicd.terraform'))}
                              onChange={() =>
                                setFieldValue(
                                  'account.features.cicd.terraform',
                                  !values.account.features.cicd.terraform
                                )
                              }
                              feedback={_.get(errors, 'account.features.cicd.terraform')}
                            />
                            <p>
                              Builds out s3 bucket and dynamodb for terraform state locking, as well as permissions on
                              cicd roles
                            </p>
                          </Form.Group>
                          <Form.Group>
                            <Form.Check
                              type="checkbox"
                              name="account.features.serviceCatalog.wafflebar"
                              label="Wafflebar"
                              checked={values.account.features.serviceCatalog.wafflebar}
                              onChange={() =>
                                setFieldValue(
                                  'account.features.serviceCatalog.wafflebar',
                                  !values.account.features.serviceCatalog.wafflebar
                                )
                              }
                            />
                            <p>
                              Enables Waffle Bar in the account. Waffle Bar is an AWS CloudFormation Transform that
                              provides a simple, declarative way of provisioning AWS resources, within your existing
                              CloudFormation template.
                            </p>
                            <p>Visit {links.waffleBarLink('here')} for more information and instructions.</p>
                          </Form.Group>
                          <Form.Group>
                            <Form.Check
                              type="checkbox"
                              name="account.features.nikePlatformEngine.enabled"
                              label="NIKE Platform Engine (NPE)"
                              checked={values.account.features.nikePlatformEngine.enabled && !values.isChina}
                              disabled={values.isChina}
                              onChange={() =>
                                setFieldValue(
                                  'account.features.nikePlatformEngine.enabled',
                                  !values.account.features.nikePlatformEngine.enabled
                                )
                              }
                            />
                            {values.isChina && <div>The NPE feature is currently unsupported in GC accounts</div>}
                            {!values.isChina && (
                              <p>
                                Enables NPE control plane operators to manage AWS resources in the account. For more
                                information about NPE, a key building block for your platform transformation, visit
                                the&nbsp;{links.npeLink('developer documentation site')}.
                              </p>
                            )}
                          </Form.Group>
                          <CicdInput formik={formikStuff} />
                        </Card.Body>
                      </Card> */}

                      <Card className="mb-2" ref={this.tagsRef}>
                        <Card.Body>
                          <Card.Title>Tagset</Card.Title>
                          <Row>
                            <Col>
                              <TagEditor
                                standard={taggingStandard}
                                tags={values.tags}
                                onTagChange={(tag: TagKeyPair) => {
                                  setFieldValue(`tags[${tag.key}]`, tag);
                                }}
                                errors={errors.tags}
                                validate={touched.tags}
                                validationResponse={values.tagValidationResponse}
                                onBlur={(tag: TagKeyPair) => setFieldTouched(`tags[${tag.key}]`, true)}
                              />
                            </Col>
                            <Col>
                              {/* <p>
                                These values can be changed later through the <span> </span>
                                <links.DocLink label="UI" href="https://cloudred.nike.com/" />.
                              </p> */}
                              <p>
                                Please see documentation on the
                                <span> </span>
                                <links.DocLink
                                  label="current Nike tagging standard"
                                  href="https://confluence.nike.com/x/2bAmDw"
                                />
                                <span> </span>
                                when specifying tagset values.
                              </p>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>

                      <Card className="mb-2">
                        <Card.Body>
                          <Card.Title>Contact Information</Card.Title>
                          <p>Contacts must be valid Active Directory usernames of Nike FTE employees.</p>
                          <Alert variant="warning">
                            Individuals with title VP and above cannot be added as Contacts
                          </Alert>
                          <Row>
                            <Col>
                              <UserTypeahead
                                name="contact.business"
                                label="Business Contact"
                                required
                                formik={formikStuff}
                                apolloClient={this.props.apollo}
                              />
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row>
                            <Col>
                              <UserTypeahead
                                name="contact.billing"
                                label="Billing Contact"
                                required
                                formik={formikStuff}
                                apolloClient={this.props.apollo}
                              />
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row>
                            <Col>
                              <UserTypeahead
                                name="contact.technical"
                                label="Technical Contact"
                                required
                                formik={formikStuff}
                                apolloClient={this.props.apollo}
                              />
                            </Col>
                            <Col>
                              <Form.Group>
                                <Form.Label></Form.Label>
                                <p>
                                  The technical contact will own AD groups used to
                                  <span> </span>
                                  <links.DocLink
                                    label="manage access to the account"
                                    href="https://confluence.nike.com/display/NDAM/Waffle+Iron+-+Get+Started#WaffleIronGetStarted-UserManagement"
                                  />
                                  <span> </span>
                                  and is responsible for approving access requests in
                                  <span> </span>
                                  <links.DocLink label="IDLocker" href="https://idlocker.nike.com" />.
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <UserTypeahead
                                name="contact.security"
                                label="Security Contact"
                                required
                                formik={formikStuff}
                                apolloClient={this.props.apollo}
                              />
                            </Col>
                            <Col></Col>
                          </Row>
                        </Card.Body>
                      </Card>
                      {!isValid ? (
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id="invalid-form-tooltip">Please correct validation errors to proceed.</Tooltip>
                          }
                        >
                          <span className="tooltip-wrapper">
                            <CreateAccountSubmitButton touched={touched} isValid={isValid} />
                          </span>
                        </OverlayTrigger>
                      ) : (
                        <CreateAccountSubmitButton touched={touched} isValid={isValid} />
                      )}
                      <LoadingWidget isLoading={isLoading} loadingState={loadingState} />
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

class CreateAccountSubmitButton extends React.Component<{ touched: FormikTouched<FormikValues>; isValid: boolean }> {
  render(): React.ReactNode {
    const { touched, isValid } = this.props;
    return (
      <Button
        variant="primary"
        type="submit"
        className="create-account-submit-btn"
        disabled={_.isEmpty(touched) || !isValid}
      >
        Create Account
      </Button>
    );
  }
}

export const CreateAccountFormWithRouter = withRouter(CreateAccountForm);

export const graphQuery = gql`
  {
    accounts {
      name
      accountType
    }
    currentUser {
      chinaCreateAccountPermission {
        hasAccess
      }
    }
    taggingStandard(id: "Latest") {
      rules {
        key_rules {
          case_rule
          illegal_strings
          required
          tag_key
          value_list
          option_list {
            id
            value
          }
        }
      }
    }
  }
`;

const dataCheckers = [
  // {
  //   serviceName: services.CLOUDRED,
  //   dataPath: ['taggingStandard', 'rules', 'key_rules'],
  // },
  {
    serviceName: services.WAFFLE_API,
    dataPath: ['accounts'],
    // validateData: (accounts: ExistingAccount[]) => accounts && !_.isEmpty(accounts),
    validateData: (accounts: ExistingAccount[]) => accounts,
  },
  // LDAP is tied to the stateful contact input component and can't be
  // checked at this level.
];

export const withAccounts = withGraphQuery(graphQuery, {
  serviceValidation: {
    dataCheckers,
  },
  options: () => ({
    errorPolicy: 'all',
  }),
});

interface CreateAccountProps {
  existingAccounts: ExistingAccount[];
  hasChinaCreatePermission: boolean;
  taggingStandard: TaggingStandard;
}

export class CreateAccount extends React.Component<WithApolloClient<CreateAccountProps>> {
  render(): React.ReactNode {
    return (
      <CreateAccountFormWithRouter
        apollo={this.props.client}
        existingAccounts={this.props.existingAccounts}
        hasChinaCreatePermission={this.props.hasChinaCreatePermission}
        taggingStandard={this.props.taggingStandard}
      />
    );
  }
}

export const CreateAccountWithApollo = withApollo(CreateAccount);

export default withAccounts((graphResponse: any) => {
  return (
    <CreateAccountWithApollo
      existingAccounts={graphResponse.data.accounts}
      hasChinaCreatePermission={graphResponse.data.currentUser.chinaCreateAccountPermission.hasAccess}
      taggingStandard={graphResponse.data.taggingStandard}
    />
  );
});
