import React from 'react';
import _ from 'lodash';
import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import { Form } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FormikValues } from 'formik';
import { redAsterisk } from '../SharedComponents/RedAsterisk';
import { User } from './types';


export function findSelectedUser(currentValue: User | null | undefined, users: User[]): User[] {
  if (currentValue) {
    return [currentValue];
  }
  else {
    return [
      _.find(users, (user: User) => _.toLower(user.username) === _.toLower(currentValue as string | undefined)),
    ].filter((el: User | undefined) => el) as User[];
  }
}

export function checkTitleIsAllowed(title: String) {
  const titleList = [/VP/, /PRESIDENT/, /CEO/, /CHIEF/];
  return !titleList.some((rx) => rx.test(title.toUpperCase()));
}

export const userSearchQuery = gql`
  query SearchUsers($searchString: String) {
    users(name: $searchString) {
      username
      name
      title
      email
    }
  }
`;

export interface UserSearchResponse {
  data: {
    users: User[];
  };
}

export interface UserTypeaheadProps {
  apolloClient: ApolloClient<any>;
  label: string;
  name: string;
  required?: boolean;
  formik: FormikValues;
}

export class UserTypeahead extends React.Component<UserTypeaheadProps> {
  state = {
    isLoading: false,
    typeaheadOptions: [],
  };

  onSearch = (searchString: string) => {
    this.setState({
      isLoading: true,
    });
    this.props.apolloClient
      .query({
        query: userSearchQuery,
        errorPolicy: 'ignore',
        variables: {
          searchString: searchString,
        },
      })
      .then((response: UserSearchResponse) => {
        const users = _.get(response, ['data', 'users'], []);
        this.setState({
          isLoading: false,
          typeaheadOptions: users,
        });
      });
  };

  render(): React.ReactNode {
    const { typeaheadOptions, isLoading } = this.state;
    const { formik, name, label, required } = this.props;
    const { setFieldTouched, setFieldValue, touched, errors, values } = formik;
    return (
      <Form.Group>
        <Form.Label>
          {label} {required && redAsterisk}
        </Form.Label>
        <AsyncTypeahead
          id={`${name}-typeahead`}
          isLoading={isLoading}
          options={typeaheadOptions}
          minLength={3}
          delay={300}
          labelKey={(o: User) => `${o.name} (${o.username}) - ${o.title} - ${o.email}`}
          onSearch={this.onSearch}
          onChange={(selected: User[]) => {
            const fieldValue = selected[0];
            const title = _.get(selected[0], 'title', '');
            if (checkTitleIsAllowed(title)) {
              setFieldValue(name, fieldValue, true);
            }
          }}
          selected={findSelectedUser(_.get(values, name), typeaheadOptions)}
          isInvalid={Boolean(_.get(touched, name) && _.get(errors, name))}
          onBlur={() => setFieldTouched(name, true)}
        />
        <Form.Control.Feedback
          className={_.get(touched, name) && _.get(errors, name) && 'show-feedback'}
          type="invalid"
        >
          {_.get(errors, name)}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
}
